@import '../../styles/customMediaQueries.css';

.root {}

.pageRoot {
  display: flex;
  padding-bottom: 90px;

  @media (min-width: 1130px) {
    padding-bottom: 0;
  }
}

.loadingText {
  margin: 24px;
}

.errorText {
  color: var(--failColor);
  margin: 24px;
}

.sectionImages {}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  position: relative;
  /* allow positioning own listing action bar */
  padding-bottom: 66.6667%;
  /* 3:2 Aspect Ratio */
  background-color: var(--matterColorNegative);
  /* Loading BG color */

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;

  @media (--viewportMedium) {
    padding-bottom: 0;
    /* No fixed aspect on desktop layouts */
  }
}

.actionBar {
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 13px;
  left: 13px;
  width: calc(100% - 26px);
  color: var(--matterColorNegative);
  background-color: var(--matterColor);
  z-index: 1;
  /* bring on top of mobile image */

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;

  border-radius: var(--borderRadius);
}

.ownListingText {
  composes: h4 from global;
  margin: 14px 12px 11px 24px;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.ownListingTextPendingApproval {
  color: var(--attentionColor);
}

.closedListingText {
  composes: h4 from global;
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.editListingLink {
  composes: h4 from global;
  flex-shrink: 0;
  margin: 0;
  padding: 14px 24px 11px 12px;
  color: var(--matterColorNegative);

  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--matterColorLight);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin: 0;
    padding: 25px 24px 22px 12px;
  }
}

.editIcon {
  margin: -6px 7px 0 0;
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */

  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    position: static;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    height: calc(0.41 * 100vw);
    max-height: 50vh;
    object-fit: cover;
    border-radius: var(--borderRadius);

    &:hover {
      transform: scale(1.005);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.viewPhotos {
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);

  /* Position and dimensions */
  position: absolute;
  bottom: 19px;
  right: 24px;
  margin: 0;
  padding: 8px 13px 6px 13px;

  /* Colors */
  background-color: var(--matterColorLight);

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);

  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.contentContainer {
  padding: 20px 20px;

  @media (--viewportLarge) {
    max-width: 1056px;
    margin: 0 auto;
    display: flex;
    position: relative;
    padding: 0;
  }

  @media (min-width: 1130px) {
    max-width: 1128px;
  }
  @media (min-width:1440px) {
    max-width: 1400px;
  }
}

.headerContainer {
  display: flex;
  @media --viewportMedium {
    margin-top: 112px;
  }
}

.reviewsSubheaderContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: solid 1px #D8DCE6;
  padding-bottom: 20px;
}

.headerImage {
  width: 100%;
  height: 520px;
  object-fit: cover;

  @media (min-width: 1130px) {
    width: calc(50vw + 131px);
  }
}

.mainContent {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  margin-bottom: 23px;
  gap:40px;

  @media (--viewportMedium) {
    margin-top: 50px;
    margin-bottom: 51px;
    flex-shrink: 0;
  }

  @media (min-width: 1130px) {
    margin-top: 50px;
    margin-bottom: 0px;
    /* contentContainer's full width - (bookingColumn + two paddings + margin + border) */
    max-width: calc(100% - 433px);
    flex-basis: calc(100% - 433px);
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.selectionBar {
  width: 100%;
  display: flex;
  border-bottom: 1px solid #D8DCE6;
  gap: 16px;
  /* Mentoring */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;

  /* Text */
  color: #2F2F2F;

}

.inactiveTab {
  margin-bottom: -1px;
  cursor: pointer;
  padding: 8px;
}

.activeTab {
  composes: inactiveTab;
  font-weight: 800;
  border-bottom: 1px solid black;
}

.bookingContainer {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  left: 0;
  height: 520px;
  padding-bottom: 32px;
  color: white;

  background: linear-gradient(0deg, #004561, #00456100);

  transition: top 0.15s ease;

  z-index: 2;

  @media (min-width: 1130px) {
    width: calc(50vw - 131px);
    color: #2F2F2F;
    position: static;
    justify-content: center;
    background: transparent;
    left: auto;
    right: 0;
  }
}

.bookingPanel {
  margin-top: 20px;

  @media (min-width: 1130px) {
    background-color: white;

    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 46px;
    gap: 16px;

    max-width: 408px;

    /* Grey border */
    border: 1px solid #D8DCE6;
    filter: drop-shadow(0px 4px 8px #bababa52);
    border-radius: 4px;
  }


}

.sectionAvatar {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -72px;

  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Flex would give too much width by default. */
  width: 60px;

  @media (--viewportMedium) {
    position: absolute;
    top: -112px;
    margin-left: 0;
    margin-top: 0;
  }
}

.avatarMobile {
  display: flex;
  width: 96px;
  height: 96px;

  @media (--viewportMedium) {
    display: none;
    /* Hide the medium avatar from the bigger screens */
  }
}

.avatarDesktop {
  display: none;
  /* Hide the large avatar from the smaller screens */
  width: 152px;
  height: 152px;

  @media (--viewportMedium) {
    display: flex;
  }
}

.initialsDesktop {
  font-size: 48px;
  font-weight: var(--fontWeightBold);
  padding-bottom: 8px;
}

.sectionHeading {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.desktopPriceContainer {
  display: flex;
  gap: 4px;
  flex-direction: row;
  align-items: center;
  flex-shrink: 0;
  padding: 0;
}

.desktopPriceValue {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
}

.desktopPerUnit {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}

.heading {
  margin-bottom: 33px;
  padding: 0 24px;

  @media (--viewportMedium) {
    margin-bottom: 0;
    padding: 0;
  }
}

.title {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;

  text-align: center;
  margin: 0;
}

.author {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
}

.authorNameLink {
  color: var(--matterColor);
}

.contactWrapper {
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.separator {
  margin: 0 6px;
}

.contactLink {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  margin: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    line-height: 16px;
  }
}


.descriptionTitle {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 27px;
  color: #2F2F2F;
  margin: 0;
  margin-bottom: 16px;
}

.sectionFeatures {
  padding: 0 24px;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 52px;
  }
}

.featuresTitle {
  /* Font */
  composes: h3 from global;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 16px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 12px;
  }
}

.description {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin: 0;
}

.deliveryNote {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 300;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.noteCheckmark {
  margin-right: 10px;
}

.sectionMap {
  padding: 0 24px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
  }
}

.locationTitle {
  /* Font */
  composes: h3 from global;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 20px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 28px;
  }
}

.sectionReviews {
  display: flex;
  flex-direction: column;
  max-width: 1128px;
  width: 100%;
  gap: 24px;
  padding: 0px 24px;

  @media (--viewportLargeWithPaddings) {
    padding: 0;
  }
  @media (min-width:1440px) {
    max-width: 1400px;
  }
}

.reviewOverallRatingContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.overallRating {
  margin: 0;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;

  color: #4A4A4A;

}

.reviewSectionSubheading {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  text-align: right;

  /* Text */
  color: #4A4A4A;
  margin: 0;
}

.overallRatingStar {
  fill: #05D6D8;
  width: 27px;
  height: 27px;
}


.reviewSectionContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;
}

.reviewsHeading {

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 27px;

  color: #4A4A4A;

}

.bookButton {
  width: 80vw;
  background-color: #05D6D8;
  border-radius: 90px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  padding: 8px;
  cursor: pointer;

  /* Text */
  color: #2F2F2F;
  @media (min-width: 1130px) {
    display: none;
    
  }
}

.contactButton {
  background-color: var(--successColor);
  line-height: 16px;
}

.sectionHost {
  position: relative;
  padding: 0 24px;
  margin-bottom: 5px;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (min-width: 1130px) {
    margin-bottom: 3px;
  }
}

.yourHostHeading {
  composes: h3 from global;
  color: var(--matterColorAnti);

  margin: 54px 0 23px 0;

  @media (--viewportMedium) {
    margin: 53px 0 27px 0;
  }

  @media (min-width: 1130px) {
    margin: 53px 0 27px 0;
  }
}

.enquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.showMoreReivewsButton {
  cursor: pointer;
  padding: 8px 24px;
  width: min-content;
  white-space: nowrap;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* Text */

  color: #2F2F2F;
  border-radius: 90px;
  border: solid 2px transparent;
  background:
    linear-gradient(to right, white, white),
    linear-gradient(80deg, #05D6D8, #00AAED);
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
}

.enquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.enquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  height: calc(100vh - 193px);
  width: 100%;

  /* Static map: dimensions are 640px */
  max-width: 640px;
  max-height: 640px;
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;

  @media (--viewportMedium) {
    height: 75vh;
  }

  @media (min-width: 1130px) {
    height: 417px;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}